import React from "react";
import { useParams } from "react-router-dom";
import blogData from "../Home/consts/blogData";
import Blog from "../Home/components/blog";
import stars from "@/scss/images/stars.png";
import circleLine from "@/scss/images/circle-lined.png";
import Button from "@/components/Common/Button";
import {
  Facebook,
  Instagram,
  Linkedin,
  Telegram,
  Twitter,
  Youtube,
} from "@/components/Icons";
const BlogDetails = () => {
  const { id } = useParams<{ id?: string }>();
  const blogIndex = id ? parseInt(id, 10) - 1 : -1;

  const blog =
    blogIndex >= 0 && blogIndex < blogData.length ? blogData[blogIndex] : null;

  if (!blog) {
    return <div>Blog not found</div>;
  }
  const currentUrl = window.location.href; // Gets the current URL of the blog post

  const socialLinks = [
    {
      id: 1,
      link: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        currentUrl
      )}`,
      icon: <Facebook />,
    },
    {
      id: 2,
      link: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        currentUrl
      )}&text=${encodeURIComponent(blog.title)}`,
      icon: <Twitter />,
    },
    {
      id: 3,
      link: `https://t.me/share/url?url=${encodeURIComponent(
        currentUrl
      )}&text=${encodeURIComponent(blog.title)}`,
      icon: <Telegram />,
    },

    {
      id: 5,
      link: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        currentUrl
      )}&title=${encodeURIComponent(blog.title)}`,
      icon: <Linkedin />,
    },
    {
      id: 6,
      link: `https://www.instagram.com/?url=${encodeURIComponent(currentUrl)}`,
      icon: <Instagram />,
    },
  ];

  return (
    <div className="mt-6 desktop:mt-10 relative">
      {" "}
      <img
        src={stars}
        alt="stars"
        className="absolute -top-10 h-[166px] desktop:h-[550px] w-full"
      />
      <div className="container max-w-[1200px]">
        <div className="gradient-border-box p-3 desktop:p-6">
          <img
            src={blog.headerImg}
            alt="Blog Header"
            className="rounded-[20px]"
          />
        </div>
        <div className="gradient-border-box mt-6 desktop:mt-10 p-4 desktop:p-6 relative mb-6 desktop:mb-10">
          <div className="text-title-lg desktop:text-headline-sm text-gold-500 mb-1">
            {blog.category}
          </div>
          <div className="text-title-sm desktop:text-subheading-md text-gold-800 mb-4">
            {blog.time}
          </div>
          <div className="text-subheading-md desktop:text-display-sm text-white">
            {blog.title}
          </div>
          <img
            src={circleLine}
            alt=""
            className="absolute rotate-180 -right-[22rem] desktop:-right-[33%] top-1/2 -translate-y-1/2"
          />
          <img
            src={circleLine}
            alt=""
            className="absolute -left-[22rem] desktop:-left-[33%] top-1/2 -translate-y-1/2"
          />
        </div>
        <div>
          {blog.paragraphs.map((item, index) => (
            <div key={index} className="mb-10">
              {item.img && (
                <div className="bg-dashboard-frame frame-effects rounded-3xl p-3 desktop:p-6 mb-8 desktop:mb-10">
                  <img
                    src={item.img}
                    alt="Blog Content"
                    className="rounded-[20px] w-full"
                  />
                </div>
              )}
              <h5 className="text-title-lg desktop:text-headline-md mb-2">
                {item.title}
              </h5>
              <p className="text-label-md desktop:text-title-md">{item.text}</p>
            </div>
          ))}
        </div>
        <div className="flex gap-3 desktop:pt-10 pb-6 relative">
          <Button model="tonal" size="sm">
            Metaverse
          </Button>
          <Button model="tonal" size="sm">
            Investment
          </Button>{" "}
          <span className="bg-golden-linear h-[1px] w-full absolute -bottom-1"></span>
        </div>
        <div className="mt-10 mb-20 gradient-border-box p-6 relative">
          <img
            src={circleLine}
            alt=""
            className="hidden desktop:block absolute rotate-180 -right-[12rem] desktop:-right-[33%] top-1/2 -translate-y-1/2"
          />
          <img
            src={circleLine}
            alt=""
            className="hidden desktop:block absolute -left-[12rem] desktop:-left-[33%] top-1/2 -translate-y-1/2"
          />
          <div className="flex justify-between items-center flex-wrap gap-8">
            <div className="text-subheading-md desktop:text-headline-sm">
              Love this post? Share it!
            </div>
            <div className="flex justify-center gap-4 flex-wrap !mx-auto desktop:!mx-0">
              {socialLinks.map((item, index) => (
                <a
                  href={item.link}
                  key={item.id}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-10 h-10 desktop:w-12 desktop:h-12 rounded-full border-solid border !border-gold-500 flex justify-center items-center"
                >
                  {item.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-[10rem]">
        <Blog data={blogData} />
      </div>
    </div>
  );
};

export default BlogDetails;
