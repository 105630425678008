import { useState, useRef, useContext, useEffect } from "react";
import { useAccount } from "wagmi";
import { useNavigate } from "react-router-dom";
import { api } from "@/utils/axiosInstance.js";
import { MapContext } from "../context.jsx";
import BaseModal from "@/components/Common/BaseModal";
import TileInfo from "./components/TileInfo.jsx";
import Button from "@/components/Common/Button";
import { useToast } from "@/components/Common/Toast/utils";
import axios from "axios";
import { convertTilesToString } from "@/utils/landHelpers.js";
import Map from "../../PurchasedLands/components/Modal/Map.jsx";

const BuyLandModal = ({ closeModalHandler, contractData }) => {
  const { landInfo, selectedCells } = useContext(MapContext);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const { address } = useAccount();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();
  const viewerRef = useRef(null);

  const captureImage = () => {
    return new Promise((resolve, reject) => {
      if (viewerRef.current) {
        console.log(viewerRef);
        const viewer = viewerRef.current;
        viewer.scene.requestRender();
        const listener = () => {
          console.log("listener");
          viewer.scene.postRender.removeEventListener(listener);
          const canvas = viewer.scene.canvas;
          const image = canvas.toDataURL("image/png");
          console.log({ image });
          if (image) {
            resolve(image);
          } else {
            reject(new Error("Failed to capture image."));
          }
        };
        viewer.scene.postRender.addEventListener(listener);
      } else {
        reject(new Error("Viewer is not available."));
      }
    });
  };
  useEffect(() => {
    if (isMapLoaded && viewerRef.current) {
      viewerRef.current.scene.requestRender();
    }
  }, [isMapLoaded]);

  const buyLandHandler = async () => {
    setIsLoading(true);
    setTimeout(async () => {
      try {
        const picture = await captureImage();
        // Proceed with axios or other actions
        await axios
          .post("https://land.pco.land/lands/save/", {
            land_id: landInfo.land_id,
            user_address: address,
            tile_data: convertTilesToString(selectedCells),
            land_image_base64: picture,
            tiles_count: landInfo.tiles_count,
          })
          .then(() => {
            setIsLoading(false);
            addToast("success", "Your request has been submitted");
            navigate("/dashboard/purchased-lands");
          })
          .catch(() => {
            setIsLoading(false);
            addToast("danger", "Try Again");
          });
      } catch (error) {
        console.error("Image capture failed:", error);
      } finally {
        setIsLoading(false);
      }
    }, 500); // Adjust delay as needed
  };
  const landDetails = selectedCells.map((cell) => {
    return { longitude: cell.centerLon, latitude: cell.centerLat };
  });

  return (
    <BaseModal
      title="Land Info"
      closeModalHandler={closeModalHandler}
      footer={
        <div className="flex gap-4 flex-wrap w-full justify-end !flex-col-reverse desktop:!flex-row">
          <Button
            model="main"
            size="sm"
            className="!w-full desktop:!w-max"
            isLoading={isLoading}
            onClick={buyLandHandler}
            disabled={!isMapLoaded}
          >
            Confirm
          </Button>
        </div>
      }
    >
      <Map
        viewerRef={viewerRef}
        setIsMapLoaded={setIsMapLoaded}
        landDetails={{ tilesCoordination: landDetails }}
      />{" "}
      <TileInfo
        tile_count={landInfo?.tiles_count}
        tilePrice={""}
        purchase_price_token={""}
      />
    </BaseModal>
  );
};

export default BuyLandModal;
